import { v4 } from 'uuid'
import { dialogComponentEnum } from '~/constants/dialog'
import type { planEnum } from '~/constants/plan'

interface IDialog {
  id: string
  isOpen: boolean
  componentName: dialogComponentEnum
  zIndex: number
}

interface IDownloadImage {
  id: number
  src: string
  isDownloaded: number
}

export const useDialogStore = defineStore('dialog', () => {
  const dialogs = ref<IDialog[]>([])
  const selectedPlanId = ref<planEnum | undefined>()
  const downloadImageInfo = ref<IDownloadImage | undefined>()
  const zIndex = ref(100)
  const isPartialRenderDialogOpen = computed(() => {
    return dialogs.value.find(d => d.componentName === dialogComponentEnum.partialRender)?.isOpen
  })

  const setSelectedPlanId = (newPlanId?: planEnum) => {
    selectedPlanId.value = newPlanId
  }

  const setDownloadImageInfo = (newDownloadImageInfo?: IDownloadImage) => {
    downloadImageInfo.value = newDownloadImageInfo
  }

  const openDialog = (componentName: dialogComponentEnum) => {
    const id = v4()
    dialogs.value.push({
      id,
      isOpen: true,
      componentName,
      zIndex: zIndex.value++,
    })
  }

  const openSubscriptionChangeDialog = (planId?: planEnum) => {
    setSelectedPlanId(planId)
    openDialog(dialogComponentEnum.subscriptionChange)
  }

  const openDownloadImageDialog = (newDownloadImageInfo?: IDownloadImage) => {
    setDownloadImageInfo(newDownloadImageInfo)
    openDialog(dialogComponentEnum.downloadImage)
  }

  const initDialogInfoByComponent = (componentName: dialogComponentEnum) => {
    switch (componentName) {
      case dialogComponentEnum.downloadImage: {
        setDownloadImageInfo()
        return
      }
      case dialogComponentEnum.subscriptionChange : {
        setSelectedPlanId()
      }
    }
  }

  const closeDialog = (id: string) => {
    const targetDialog = dialogs.value.find(d => d.id === id)
    if (targetDialog) {
      targetDialog.isOpen = false
      initDialogInfoByComponent(targetDialog.componentName)
    }
  }

  const deleteDialog = (id: string) => {
    const index = dialogs.value.findIndex(d => d.id === id)
    if (index !== -1) {
      const targetDialog = dialogs.value[index]
      if (targetDialog.componentName !== dialogComponentEnum.partialRender) {
        initDialogInfoByComponent(targetDialog.componentName)
        dialogs.value.splice(index, 1)
      }
    }
  }

  const closeAllDialogs = () => {
    dialogs.value = []
    setDownloadImageInfo()
    setSelectedPlanId()
  }

  const showDialog = (componentName: dialogComponentEnum) => {
    const targetDialog = dialogs.value.find(d => d.componentName === componentName)
    if (targetDialog) {
      targetDialog.isOpen = true
    } else {
      openDialog(componentName)
    }
  }

  return {
    dialogs,
    selectedPlanId,
    openSubscriptionChangeDialog,
    downloadImageInfo,
    openDialog,
    closeDialog,
    deleteDialog,
    closeAllDialogs,
    setSelectedPlanId,
    setDownloadImageInfo,
    openDownloadImageDialog,
    showDialog,
    isPartialRenderDialogOpen,
  }
})
